.radio-wrapper {
  display: flex;
  align-items: center;
  > label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 35px;
    > input {
      display: none;
    }
    > .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 21px;
      width: 21px;
      background-color: #eee;
      border-radius: 50%;
      &::after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: white;
        display: none;
      }
    }
    .checkmark ~ .vector-icon-wrapper {
      display: flex;
      margin-left: 15px;
    }
    > input[type="checkbox"]:checked ~ .checkmark {
      background-color: var(--fav-color);
      &::after {
        display: block;
      }
    }
  }
}
