.header {
  padding: 35px 120px;
  @media screen and (max-width: 768px) {
    padding: 30px 10px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  &-logo {
    display: flex;
    cursor: pointer;
  }
  &-actions {
    > nav {
      display: flex;
    }
    .nav-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
}
