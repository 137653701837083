.overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    width: 50%;
    background-color: var(--bodyBack-color);
    border-radius: 15px;
    padding: 50px;
    &-modal {
    }
  }
}
