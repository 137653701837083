.voting-container {
  padding: 70px 120px;
  @media screen and (max-width: 1024px) {
    padding: 70px 0px;
  }
  .heading-wrapper {
    justify-content: flex-start;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    > h1 {
      font-family: var(--popins);
    }
  }
  .voting-content {
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
    .person-wrapper {
      flex: 1;
      overflow: hidden;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
      .person-avatar {
        position: relative;
        // > img {
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        // }
        .person-vote-wrapper {
          position: absolute;
          top: 5px;
          left: 5px;
          width: 80px;
          height: 70px;
          background: rgba(237, 237, 237, 0.4);
          border-radius: 6px;
          > label {
            width: 100%;
            height: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            > input {
              display: none;
            }
            > input:checked ~ .customRadio::after {
              display: block;
            }
            .customRadio {
              width: 24px;
              height: 24px;
              display: block;
              border: 2px solid #8f00ff;
              border-radius: 50%;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 12px;
                height: 12px;
                background-color: #8f00ff;
                border-radius: 50%;
                display: none;
              }
            }
          }
        }
      }
      .person-info {
        @media screen and (max-width: 550px) {
          padding: 0 30px;
        }
        &-name {
          margin-top: 25px;
          margin-bottom: 15px;
          font-weight: var(--bold-fw);
          font-size: var(--h5-fz);
          text-transform: uppercase;
        }
        &-proff {
          margin-bottom: 25px;
          font-size: var(--h5-fz);
          color: #b7b7b7;
          text-transform: capitalize;
        }
        &-rate {
          background-color: #263238;
          border-radius: 110px;
          width: 60%;
          padding: 5px 10px;
          margin: 30px 0;
        }
      }
      .slick-slider {
        width: 380px;
        img {
          width: 100%;
          height: 305px;
          object-fit: cover;
          @media screen and (max-width: 550px) {
            width: 100%;
          }
        }
        * {
          border: none;
          outline: none;
        }
      }
      .slick-prev,
      .slick-next {
        z-index: 999;
        &::before {
          content: none;
        }
        width: 50px;
        height: 50px;
        padding: 13px;
      }
      .slick-prev {
        left: 0 !important;
      }
      .slick-next {
        right: 0 !important;
      }
    }
    & ~ .simple-btn-wrapper {
      margin-top: 100px;
    }
  }
}
