.select-wrapper {
  > input {
    cursor: pointer;
  }
  .vector-icon-wrapper {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  .select-dropdown {
    position: absolute;
    z-index: 100;
    width: 100%;
    overflow-y: auto;
    max-height: 500px;
    &-option {
      padding: 20px;
      background: rgba(69, 69, 69, 1);
      border: 1px solid #ffffff;
      outline: none;
      font-size: var(--input-fz);
      color: #ffffff;
      &:hover {
        background: rgba(89, 89, 89, 1);
      }
    }
  }
}
