.create-voting-container {
  padding: 70px 120px;
  @media screen and (max-width: 1024px) {
    padding: 70px 20px;
  }
  .heading-wrapper {
    justify-content: flex-start;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
    > h1 {
      font-family: var(--popins);
    }
  }
}
