.vote-pers-form {
  display: flex;
  flex-direction: column;
  // @media screen and (max-width: 1024px) {
  //   flex-wrap: wrap;
  // }
  &-row {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    > div {
      flex: 1;
      margin-left: 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:first-child {
        margin-left: 0;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

      .input-wrapper {
        > input {
          padding: 10px;
        }
      }
      .textarea-wrapper {
        > textarea {
          resize: none;
          height: 135px;
          padding: 10px;
        }
      }
      .vote-pers-avatar {
        position: relative;
        height: 350px;
        border: 1px solid white;
        margin-bottom: 25px;
        > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        &-label {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          cursor: pointer;
        }
        .input-wrapper {
          display: none;
        }
        &-alt-images {
          margin-bottom: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          > div {
            margin: 5px;
            img {
              width: 100px;
              height: 100px;
            }
          }

          .alt-images-add {
            > label {
              width: 50px;
              height: 50px;
              display: flex;
              border: 2px solid white;
              border-radius: 50%;
              cursor: pointer;
              align-items: center;
              justify-content: center;
            }
            .input-wrapper {
              display: none;
            }
          }
        }
      }
    }
  }

  & ~ .simple-btn-wrapper {
    margin-top: 45px;
  }
}
