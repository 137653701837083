@import "./assets/scss/_variables";
@import "./assets/scss/_reset";
@import "./assets/scss/_mixins";
@import "./assets/scss/_baseStyles.scss";

.App {
  @media screen and (min-width: 1920px) {
    display: flex;
    justify-content: center;
  }
}
main {
  position: relative;
}
