@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Righteous&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  color: var(--body-color);
  background-color: var(--bodyBack-color);
  font-family: var(--popins);
  font-size: var(--body-fz);
  font-weight: var(--regular-fw);
}
button {
  border: none;
}
a {
  text-decoration: none;
}
