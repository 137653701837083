.edit-profile-form-wrapper {
  display: flex;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.main-content {
  display: flex;
  min-height: calc(100vh - 71px);
  &-left {
    border-right: 1px solid white;
    padding-top: 70px;
    width: 600px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      border-right: none;
    }
    .avatar-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      cursor: pointer;
    }
    .input-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: none;
    }
    .avatar-wrapper {
      position: relative;
      width: 525px;
      height: 350px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 0px 30px 30px 0px;
        object-fit: cover;
      }
      @media screen and (max-width: 550px) {
        width: 100%;
        padding: 0 20px;
        > img {
          border-radius: 30px;
        }
      }
    }
    .desc-wrapper {
      text-align: center;
      width: 525px;
      @media screen and (max-width: 550px) {
        width: 100%;
      }
      > h3 {
        margin-top: 35px;
        font-family: var(--popins);
        font-weight: var(--bold-fw);
        font-size: var(--h3-fz);
        letter-spacing: 0.06em;
        text-transform: uppercase;
      }
      > h4 {
        margin-top: 5px;
        color: #b7b7b7;
        font-weight: var(--regular-fw);
        font-size: var(--h4-fz);
        letter-spacing: 0.06em;
      }
      > p {
        margin-top: 25px;
        padding: 0 25px;
        word-break: break-word;
      }
    }
  }
  &-right {
    flex: auto;
    padding: 75px;
    @media screen and (max-width: 768px) {
      padding: 75px 20px;
    }
    > h1 {
      font-size: var(--h1-fz);
      font-weight: var(--regular-fw);
      letter-spacing: 0.06em;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }
}

.edit-profile-form {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:last-child {
      margin-left: 20px;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
    }
    .input-wrapper {
      width: 100%;
      margin-bottom: 30px;
    }
    .desc-area {
      display: flex;
      flex-direction: column;

      > div {
        .textarea-wrapper {
          > textarea {
            resize: none;
            padding: 20px;
            height: 113px;
          }
        }
        > p {
          margin-bottom: 10px;
        }
      }
    }
  }
  & ~ .simple-btn-wrapper {
    margin-top: 45px;
  }
}
.delete-account-wrapper {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 550px) {
    margin-top: 40px;
  }

  > p {
    cursor: pointer;
    color: #b7b7b7;
  }
}
