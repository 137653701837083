.err,
.error {
  margin-top: 5px;
  color: red;
  position: absolute;
  left: 0;
  top: 100%;
}
.success {
  margin-top: 5px;
  color: green;
}
//********************** Heading Styles **********************//
.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  > h1 {
    font-family: var(--righteous);
    font-size: var(--h1-fz);
    font-weight: var(--regular-fw);
    letter-spacing: 12.5px;
    text-transform: uppercase;
  }
}
//********************** Simple Button Styles **********************//
.simple-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  > button {
    width: 265px;
    height: 65px;
    background: var(--fav-color);
    border-radius: 6px;
    font-size: 20px;
    font-weight: var(--semi-bold-fw);
    letter-spacing: 2.5px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
  }
}

//********************** Form Styles **********************//

.login-form-wrapper,
.register-form-wrapper {
  min-width: 550px;
  @media screen and (max-width: 768px) {
    min-width: 375px;
    padding: 20px;
  }
}
.login,
.register {
  &-form {
    margin-top: 70px;
  }
  &-actions {
    margin-top: 35px;
    &-top {
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > a {
        display: block;
        color: white;
        text-decoration: none;
        font-size: 18px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    &-bottom {
      margin-top: 45px;
      .other-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        > p {
          font-size: 20px;
          margin-right: 20px;
        }
        > a {
          display: block;
          color: var(--fav-color);
          font-weight: var(--semi-bold-fw);
          font-size: 20px;
        }
      }
    }
  }
}
.remember-wrapper {
  display: flex;
  align-items: center;
  > label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    > .rememberMe {
      margin-left: 35px;
      @media screen and (max-width: 768px) {
        margin-left: 25px;
      }
    }
    > #remember {
      display: none;
    }
    > .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 26px;
      width: 26px;
      background-color: #eee;
      @media screen and (max-width: 768px) {
        height: 18px;
        width: 18px;
      }
      &::after {
        content: "";
        position: absolute;
        left: 10px;
        top: 5px;
        @media screen and (max-width: 768px) {
          left: 6px;
          top: 2px;
        }
        width: 5px;
        height: 10px;
        border: solid #000;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        display: none;
      }
    }
    > input[type="checkbox"]:checked ~ .checkmark {
      background-color: var(--fav-color);
      &::after {
        display: block;
      }
    }
  }
  // > input[type="checkbox"] {
  //   width: 26px;
  //   height: 26px;
  //   background-color: red;
  // }
  // > input[type="checkbox"]:checked {
  //   color: #000;
  //   background-color: var(--fav-color);
  // }
}
