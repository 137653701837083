.input-wrapper {
  margin-bottom: 30px;
  position: relative;
  > label {
    text-transform: capitalize;
    font-size: 16px;
    margin-bottom: 5px;
  }
  > input,
  textarea {
    padding: 20px 0;
    padding-left: 60px;
    padding-right: 20px;
    background: rgba(69, 69, 69, 0.6);
    border: 1px solid #ffffff;
    width: 100%;
    outline: none;
    font-size: var(--input-fz);
    color: #ffffff;
  }
}
input::-webkit-datetime-edit-month-field,
input::-webkit-datetime-edit-day-field,
input::-webkit-datetime-edit-year-field,
input::-webkit-datetime-edit-text,
input::-webkit-calendar-picker-indicator {
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: transparent;
  outline: none;
}
input::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
  cursor: pointer;
}
input::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
