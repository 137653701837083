:root {
  //  Font Styles  //

  //  FFamily //
  --popins: "Poppins", sans-serif;
  --righteous: "Righteous", cursive;

  //  FW //
  --regular-fw: 400;
  --medium-fw: 500;
  --semi-bold-fw: 600;
  --bold-fw: 700;

  //  FZ //
  --h1-fz: 36px;
  --h3-fz: 30px;
  --h4-fz: 24px;
  --h5-fz: 18px;
  --input-fz: 18px;
  --body-fz: 14px;

  //  Colors //

  --fav-color: #8f00ff;
  --bodyBack-color: #000;
  --body-color: #fff;
}
